import { Fragment, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Metrics from '@spotahome/soyuz-tracking';
import { getResourcePath } from '@spotahome/soyuz/client';

import {
  getPoiRequest,
  setPoiRequest,
  deletePoiRequest
} from '@spotahome/marketplace-common/src/api/poi-client';
import {
  getMapTypeOptions,
  getZoomOptions
} from '@spotahome/marketplace-common/src/utils/google-maps';
import PlacesAutocomplete from '@spotahome/marketplace-common/src/components/PlacesAutocomplete';
import MapsAPI from '@spotahome/marketplace-common/src/LEGACY/javascripts/ui/map/maps-api';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import DistanceInfo from './DistanceInfo';

import './InteractiveMap.scss';

const BIG_MARKER = getResourcePath(
  '/bundles/sahapp/images/ui/big-marker-selected.svg'
);

const MARKER_KEY = 'property';
const PLACE_SELECTED_EVENT_CATEGORY = 'interactive-map-add-reference-point';
const INITIAL_LISTING_ZOOM = 5;
const sendOpenEvent = () => {
  Metrics.ga.sendEvent('interactive-map', 'open-map');
  SoyuzAnalytics.sendGA4Event('interactive-map', { action: 'open-map' });
};

const sendSearchEvent = place => {
  const { name, lat, lng } = place;

  if (name && lat && lng) {
    Metrics.ga.sendEvent(PLACE_SELECTED_EVENT_CATEGORY, name, `${lat},${lng}`);
    SoyuzAnalytics.sendGA4Event(PLACE_SELECTED_EVENT_CATEGORY, {
      place: name,
      coord: `${lat},${lng}`
    });
  }
};

const initializeMap = mapId =>
  new MapsAPI(
    mapId,
    {
      transitLayer: true,
      disableDefaultUI: false,
      mapTypeControl: true,
      mapTypeControlOptions: getMapTypeOptions(),
      zoomControlOptions: getZoomOptions(),
      streetViewControl: true,
      mapTypeId: 'roadmap',
      zoom: INITIAL_LISTING_ZOOM
    },
    {
      markerIcon: BIG_MARKER,
      poiRadius: 100
    }
  );

const InteractiveMap = ({ mapId, coords, areaSlug, countryCode }) => {
  const [pointOfInterest, setPointOfInterest] = useState(null);
  const map = useRef();

  useEffect(() => {
    (async () => {
      try {
        const poiResponse = await getPoiRequest(areaSlug);
        setPointOfInterest(poiResponse.data.poi);
      } catch (error) {
        console.error('Error in POI request', error);
      }
    })();
  }, []);

  useEffect(() => {
    const [lng, lat] = coords;
    map.current = initializeMap(mapId);
    map.current.setCenter({ lat, lng });

    map.current.addMarkers([
      {
        id: MARKER_KEY,
        coord: [lng, lat]
      }
    ]);
    map.current.fitBoundsToMarkers();
    sendOpenEvent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pointOfInterest) {
      map.current.addPointOfInterest(pointOfInterest);
      map.current.fitBoundsToMarkers();
    } else {
      map.current.removePointOfInterest();
      map.current.fitBoundsToMarkers();
    }
  }, [pointOfInterest]);

  const handlePlaceSelected = place => {
    if (place) {
      setPointOfInterest(place);
      setPoiRequest(areaSlug, place);
      sendSearchEvent(place);
    } else {
      setPointOfInterest(null);
      deletePoiRequest(areaSlug);
    }
  };

  const coordsObj = {
    lat: parseFloat(coords[1]),
    lng: parseFloat(coords[0])
  };

  return (
    <Fragment>
      <div className="interactive-map__header">
        <PlacesAutocomplete
          flat
          boundCoords={coordsObj}
          pointOfInterest={pointOfInterest}
          onPlaceSelected={handlePlaceSelected}
          countryCode={countryCode}
        />
        <DistanceInfo
          originCoords={coordsObj}
          pointOfInterest={pointOfInterest}
        />
      </div>
      <div className="interactive-map__map" id={mapId} />
    </Fragment>
  );
};

InteractiveMap.propTypes = {
  coords: PropTypes.arrayOf(PropTypes.number).isRequired,
  mapId: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  areaSlug: PropTypes.string.isRequired
};

export default InteractiveMap;
