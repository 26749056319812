import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  trans,
  useSoyuzClientConfig,
  useSoyuzLocales
} from '@spotahome/soyuz/client';
import Metrics from '@spotahome/soyuz-tracking';

import WoosmapClient from '@spotahome/marketplace-common/src/api/woosmap-client';

import DistanceAPI from '../../services/DistancesAPI';

import {
  CYCLING_TRANSPORT,
  DRIVING_TRANSPORT,
  TRANSIT_TRANSPORT,
  WALKING_TRANSPORT
} from '../../constants';

import CyclingIcon from './icons/CyclingIcon';
import DrivingIcon from './icons/DrivingIcon';
import TransitIcon from './icons/TransitIcon';
import WalkingIcon from './icons/WalkingIcon';

import './DistanceInfo.scss';

const TRANSPORT_KEYS = {
  [CYCLING_TRANSPORT]: 'rentable_unit_map.bicycling',
  [DRIVING_TRANSPORT]: 'rentable_unit_map.driving',
  [TRANSIT_TRANSPORT]: 'rentable_unit_map.transit',
  [WALKING_TRANSPORT]: 'rentable_unit_map.walking'
};

const TRANSPORT_ICONS = {
  [CYCLING_TRANSPORT]: CyclingIcon,
  [DRIVING_TRANSPORT]: DrivingIcon,
  [TRANSIT_TRANSPORT]: TransitIcon,
  [WALKING_TRANSPORT]: WalkingIcon
};

const DistanceResult = ({ duration, transportMean }) => {
  const TransportIcon = TRANSPORT_ICONS[transportMean];

  return transportMean ? (
    <div className="distance-info__wrapper">
      {TransportIcon && <TransportIcon className="distance-info__icon" />}
      <span className="distance-info__time">{duration}</span>{' '}
      <span className="distance-info__transport-mean">
        {trans(TRANSPORT_KEYS[transportMean])}
      </span>
    </div>
  ) : null;
};

DistanceResult.propTypes = {
  duration: PropTypes.string.isRequired,
  transportMean: PropTypes.oneOf(Object.keys(TRANSPORT_ICONS)).isRequired
};

const useWoosmapClient = () => {
  const woosmapClient = useRef(null);

  const { woosmapApiKey } = useSoyuzClientConfig();
  const { current: currentLocale } = useSoyuzLocales();

  woosmapClient.current = WoosmapClient(woosmapApiKey, currentLocale);

  return woosmapClient.current;
};

const DistanceInfo = ({ originCoords, pointOfInterest }) => {
  const [loading, setLoading] = useState(false);
  const [distanceResult, setDistanceResult] = useState(null);

  const woosmapClient = useWoosmapClient();
  const distanceService = new DistanceAPI(woosmapClient);

  const getDistanceToPOI = async (origin, destination) => {
    Metrics.ga.sendEvent('interactive-map', 'distance-to-reference-point');
    setLoading(true);

    try {
      const results = await distanceService.getAllDistances(
        origin,
        destination
      );
      setDistanceResult(results);
    } catch (error) {
      setDistanceResult(null);
      console.error(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (originCoords && pointOfInterest) {
      getDistanceToPOI(originCoords, pointOfInterest);
    } else {
      setDistanceResult(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originCoords, pointOfInterest]);

  if (loading) return `${trans('loading')} ...`;
  if (!distanceResult || !distanceResult.length) return null;

  return (
    <div className="distance-info">
      {distanceResult.map(distance => {
        const transportMean = distanceService.getTransportMean(
          distance.travelMode
        );

        return (
          <DistanceResult
            key={transportMean}
            duration={distance.duration.text}
            transportMean={transportMean}
          />
        );
      })}
    </div>
  );
};

DistanceInfo.propTypes = {
  originCoords: PropTypes.shape({}),
  pointOfInterest: PropTypes.shape({})
};

DistanceInfo.defaultProps = {
  originCoords: null,
  pointOfInterest: null
};

export default DistanceInfo;
